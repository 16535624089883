<template>
    <div class="news-details page" v-if="currentPromoNews != null">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12" lg="6">
                    <h1>{{currentPromoNews.name}}</h1>
                    <div class="date">{{publishedDate}}</div>
                    <div class="lead" v-html='currentPromoNews.short_description'></div>
                </b-col>
                <b-col cols="12" lg="6">
                    <img  v-if="currentPromoNews.image && itemSrc(currentPromoNews, 'w1080h714') != 'no-image' && itemSrcset(currentPromoNews) != 'no-image'"
                        sizes="(max-width: 767px) 100vw, 50vw" 
                        :srcset="itemSrcset(currentPromoNews)"
                        :src="src_url + '/' + itemSrc(currentPromoNews, 'w1080h714')" 
                        class="w-100 main-img"
                    />
                    <!-- noimage -->
                    <img v-else src="@/assets/images/noimages/noimage-news.jpg" class="w-100"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <div class="content" v-html='currentPromoNews.description'></div>
                </b-col>
            </b-row>
            <b-row v-if="promotions.length > 0">
                <b-col cols="12">
                    <h2>Promóciók</h2>
                    <NewsItemBig 
                            v-for='pnews in promotions' :key="pnews.id" 
                            :news="pnews"
                            :isPromotion="true"
                    />
                </b-col>
            </b-row>
            <b-row v-if="otherNews.length > 0">
                <b-col cols="12"><h2>További hírek</h2></b-col>
                <b-col cols="12" lg="6" v-for='news in otherNews' :key="news.id"  >
                    <NewsItem                                     
                        :news="news"
                    />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import NewsItemBig from '@/components/pages/NewsItemBig.vue'
import NewsItem from '@/components/pages/NewsItem.vue'

export default {
    mixins: [apiH],   
    data(){
        return{
            currentPromoNews:null,
            promotions:[],
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME,
            otherNews:[]
        }
    },
    components:{
        NewsItemBig,
        NewsItem
    },
    created(){
       this.loadNews() 
       this.loadPromosions()
    },
    computed:{
        publishedDate(){            
            return this.getTimeDate(this.currentPromoNews.published_at)
        },
        metaTitle()  {
            return this.currentPromoNews.meta_title != '' ? this.currentPromoNews.meta_title : this.currentPromoNews.name
        }
    },
    watch:{
        $route (){
            this.loadNews()
            this.loadPromosions()
        }
    },
    methods:{
      
        loadNews(){
            var _this = this;            

            _this.get('news',
                {
                    params: {                        
                        where: [['where','status', '1']],
                        order_by:[
                            ['published_at','desc']                
                        ], 
                    }
                },
                function(resp){                                        
                    _this.sortNews(resp.data.news)
                }, function(err){
                    console.log(err);     
                    _this.currentPromoNews = null
                    _this.otherNews = []                    
                }
            )
        },
        sortNews(newslist){
            var _this = this   
            var currentSlug = this.$route.params.news_slug                         
            
            this.currentPromoNews = newslist.find( item => currentSlug == item.slug )

            if (this.currentPromoNews == null || this.currentPromoNews == undefined){
                _this.$router.push({name:'404-page'})                    
            }
            
            var count = 0
            this.otherNews = []
            
            newslist.forEach(element => {                
                if ( _this.currentPromoNews != null || _this.currentPromoNews != undefined) {
                    if ( element.slug != _this.currentPromoNews.slug && count < 2) {
                        _this.otherNews.push(element)
                        count += 1
                    }
                }                 
            });

             this.setMetaTags({
                title: 'Kunsági Éden - ' + this.metaTitle, 
                desc: '', 
                keywords: '', 
                ogType: 'website', 
                ogTitle: 'Kunsági Éden - ' +this.metaTitle,
                ogImage: null, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc:''
            })

        },
        loadPromosions(){
            var _this = this;            

            _this.get('promotion',
                {
                    params: {                        
                        where: [['where','status', '1']],
                        order_by:[
                            ['published_at','desc']                
                        ], 
                    }
                },
                function(resp){                                        
                    _this.promotions = resp.data.promotion
                }, function(err){
                    console.log(err);
                     _this.promotions = []
                }
            )
        },           
    }
}
</script>